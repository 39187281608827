import { styled, alpha } from "@mui/material/styles";
import { Toolbar, OutlinedInput } from "@mui/material";

export const StyledRoot = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(2, 1, 2, 3),
}));

export const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 340,
  // width: '20%',
  borderRadius: "24px",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 360,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));
