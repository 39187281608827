import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  checkURL,
  getLink,
  getUploadedImage,
} from "src/sections/modals/order/utils";
import request from "src/utils/request";
import Variations from "./Variations";
import Validations from "./Validations";
import ImageViewPopup from "../image-viewer/ImageViewPopup";
import OrderGraphics from "src/sections/modals/order/orderGraphics";
import UploadGraphics from "src/sections/modals/order/UploadGraphics";
import { toastify } from "src/utils/toast";
import { BackupSharp } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "src/redux/actions/modalActions";
import { getAllOrdersList } from "src/pages/orders/requests/icp";

function NestedTable({ order, orderStatus }) {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.user.user.type);

  const [orders, setOrders] = useState(
    order.orderDetails ? order.orderDetails : []
  );

  const page =
    sessionStorage.getItem("page") !== null
      ? sessionStorage.getItem("page")
      : 1;

  const rowsPerPage =
    sessionStorage.getItem("rowsPerPage") !== null
      ? sessionStorage.getItem("rowsPerPage")
      : 25;

  const tabValue = sessionStorage.getItem("activeTabIndex")
    ? sessionStorage.getItem("activeTabIndex")
    : "1";

  useEffect(() => {
    if (order.orderDetails) {
      setOrders(order.orderDetails);
    }
  }, [order.orderDetails, setOrders]);

  const handleClick = async (e, item) => {
    setOrders(
      orders.map((order) => {
        return order.id === item.id
          ? { ...order, ready_for_approval: e.target.checked ? 1 : 0 }
          : order;
      })
    );

    const payload = {
      order_details_id: item.id,
      ready_for_approval: e.target.checked ? 1 : 0,
    };

    try {
      const res = await request.post(`/orders/ready/approval`, payload);
      if (res) {
        getAllOrdersList(dispatch, userType, page, rowsPerPage, tabValue);
      }
    } catch (e) {
      toastify("error", e.response.data.message);
    }
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <TableContainer component={Paper} style={{ overflowX: "auto" }}>
      <Table sx={{ minWidth: isMobile ? "100%" : 650 }}>
        <TableHead>
          <TableRow
            sx={{
              "& th:first-of-type": {
                borderTopLeftRadius: "16px",
              },
              "& th:last-of-type": {
                borderTopRightRadius: "16px",
              },
            }}
          >
            <TableCell align="center">Preview image</TableCell>
            <TableCell align="left">Product Name</TableCell>
            <TableCell align="center">Graphic</TableCell>
            <TableCell align="center">Upload Graphic</TableCell>
            <TableCell align="center">Ready for Approval</TableCell>
            <TableCell align="center">Finalized Graphic</TableCell>
            <TableCell align="left">Variations</TableCell>
            <TableCell align="left">Graphic Dimensions</TableCell>
            {orderStatus === "shipped" || orderStatus === "fulfilled" ? (
              <TableCell align="center" sx={{ minWidth: "200px" }}>
                Tracking ID
              </TableCell>
            ) : (
              ""
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((item) => {
            return (
              <TableRow
                key={item.id}
                sx={{
                  backgroundColor:
                    item?.catalog?.print_provider === null ? "" : "#F0FFFF",
                }}
              >
                <TableCell align="center">
                  <ImageViewPopup
                    imageSRC={[
                      item.preview_image === null
                        ? item.catalog.images[0]
                        : item.preview_image,
                    ]}
                  />
                </TableCell>
                <TableCell align="left">
                  <h6>{item.catalog.title}</h6>
                </TableCell>
                <TableCell align="center">
                  {item?.graphic_image === null ? (
                    ""
                  ) : item?.catalog?.no_of_graphics === 0 ? (
                    ""
                  ) : item?.catalog?.no_of_graphics === 1 ? (
                    checkURL(item.graphic_image) === "url" ? (
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() =>
                          window.open(getLink(item.graphic_image), "_blank")
                        }
                        disabled={userType === "Seller" ? false : true}
                      >
                        Edit Graphic
                      </Button>
                    ) : (
                      <ImageViewPopup
                        imageSRC={[getUploadedImage(item, "graphic")]}
                        overlap
                      />
                    )
                  ) : (
                    item?.catalog?.no_of_graphics === 2 && (
                      <>
                        {checkURL(item?.graphic_image) === "url" ||
                        checkURL(item?.notecard_image) === "url" ? (
                          <Button
                            variant="contained"
                            size="small"
                            disabled={userType === "Seller" ? false : true}
                            onClick={() =>
                              dispatch(
                                setModalState(<OrderGraphics item={item} />)
                              )
                            }
                          >
                            View Graphics
                          </Button>
                        ) : (
                          <ImageViewPopup
                            imageSRC={getUploadedImage(item)}
                            overlap
                          />
                        )}
                      </>
                    )
                  )}
                </TableCell>

                <TableCell align="center">
                  {item?.catalog?.no_of_graphics === 0 ? (
                    ""
                  ) : (
                    <IconButton
                      color="primary"
                      disabled={orderStatus === "on hold" ? false : true}
                      onClick={() =>
                        dispatch(
                          setModalState(<UploadGraphics item={item} />, true)
                        )
                      }
                    >
                      <BackupSharp />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    id={`row-${item.id}`}
                    disabled={orderStatus === "on hold" ? false : true}
                    checked={item.ready_for_approval === 1 ? true : false}
                    onChange={(event) => handleClick(event, item)}
                  />
                </TableCell>

                <TableCell align="center">
                  <ImageViewPopup imageSRC={getUploadedImage(item)} overlap />
                </TableCell>

                <TableCell align="left">
                  <Variations item={item} />
                </TableCell>
                <TableCell align="left">
                  <Validations item={item} />
                </TableCell>
                {orderStatus === "shipped" || orderStatus === "fulfilled" ? (
                  <TableCell align="center" sx={{ minWidth: "200px" }}>
                    <Typography variant="body2">
                      {item?.tracking_id ? item?.tracking_id : "-"}
                    </Typography>
                  </TableCell>
                ) : (
                  ""
                )}
              </TableRow>
            );
          })}
          {orders.length === 0 && (
            <TableRow>
              <TableCell colSpan={order?.is_etsy === 1 ? 7 : 6} align="center">
                <Typography variant="h6" paragraph>
                  There are no items in this order.
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default NestedTable;
